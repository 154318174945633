/* navigation bar */

/* .nav-bar {
  background: #181818;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  min-height: 500px;
}

.nav-bar nav{
  display: block;
  text-align: center;
  position: absolute;
  height: 210px;
  top: 50%;
  margin-top: -120px;
  width: 100%;
}

.nav-bar nav a{
  font-size: 25px;
  color: #4d4d4e;
  display: block;
  line-height: 51px;
  height: 51px;
  position: relative;
  text-decoration: none;
}

.nav-bar nav a:hover{
  transition-duration: 0.5s;
  color: darkmagenta;
}
*/


.nav ul {
position: absolute;
bottom: 0px;
width: 100%;
display: block;
padding: 0;
list-style: none;
text-align: center;
margin: 0;
}



.nav li a {
color: #4d4d4e;
text-decoration: none;
padding: 7px 0;
display: block;
font-size: 20px;
line-height: 16px;
}
.nav li a:hover,
.nav li a:focus {
transition-duration: 0.5s;
color: darkmagenta;
text-decoration: underline;
} 

header.mdl-layout__header.nav-header.is-casting-shadow {
  background-color: #181818;
  
}

.nav-title {
  color: white;
}

.nav-title:focus,
.nav-title:hover {
  color: white;
}


.nav-title-sub{
  color: #757575;
}

.nav-title-sub:hover{
  color: #757575;
}

