/* font-family: 'Righteous', cursive;
font-family: 'Acme', sans-serif;
font-family: 'Indie Flower', cursive; */

body {
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, #414345, #232526);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #414345, #232526);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.title {
  // background-color: green;

  text-align: center;
  height: 10vh;
  line-height: 70px;
  margin: 40px 0;
}

.landing-container {
  .buttons {
    padding-top: 1%;

    a {
      color: #4d4d4e;
      text-decoration: none;

      font-size: 20px;
      line-height: 40px;
    }
    a:hover,
    a:focus {
      transition-duration: 0.5s;
      color: darkmagenta;
      text-decoration: underline;
    }
  }
}

.intro {
  background: rgba(255, 255, 255, 0.29);
  text-align: center;
  // padding: 8% 5%;
  font-family: "Indie Flower", cursive;
  border-radius: 2em;
  padding-bottom: 15px;

  h5 {
    font-family: "Indie Flower", cursive;
    color: white;
  }

  a {
    font-size: 20px;
    color: white;
    text-decoration-line: underline;
  }
}

.Typist .Cursor {
  display: inline-block;

  &--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
    @keyframes blink {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
    }
  }
}

.profile-pic {
  width: 100%;
  border-radius: 2em !important;
}

.landing-header {
  font-family: "Righteous", cursive;
  text-align: center;
  font-size: 5em;
  color: white;
  // background-color: greenyellow;
}

/* About me */

.about-me {
  background: rgba(255, 255, 255, 0.29);
  border-radius: 2em !important;
  animation-duration: 1s;
  animation-delay: 8s;
}

.part1 {
  animation-duration: 1s;
  animation-delay: 9s;
  
  padding: 100px;
}

.part2 {
  animation-duration: 1s;
  animation-delay: 10s;
  
  padding: 5vh;
}

.about {
  // margin: auto;
  max-width: 90%;
  text-align: center;

  h2 {
    text-align: center;
    font-size: 4em;

    font-family: "Acme", sans-serif;
  }
}

.skills {
  h2 {
    font-family: "Acme", sans-serif;
  }
}

.items {
  background: rgba(255, 255, 255, 0.29);
  color: white;
  text-align: center;
  // padding: 8% 5%;
  font-family: "Indie Flower", cursive;
  border-radius: 0.5em;
  p {
    font-size: 1em;
    font-family: "Acme", sans-serif;
    color: black;
  }
}

.cv {
  padding-top: 25px;
}

.cv-button {
  background-color: darkgrey;

}

// ------------------- Projects page ------------------- 

.project-page {
  h3{
    color: white;
    padding-left: 50px;
  }
}



.project-box {
  background: #fff;
  border: 1px solid black;
  border-radius: 3px;
  width: 40vh;
  // height: vh;
  margin: 10px;
  padding: 0px;
  float: left;
  // text-align: center;
  a {
    margin: 10px 10px 0 10px;
    background-color: #414345;
    &:hover {
      background-color: darkmagenta;
    }
  }
}

.project-page{
  h3{
    font-family: "Acme", sans-serif;
  }
}

.project-info{
  height: 30px;
  text-align: center;
}

.project-button {
  text-align: center;
  padding-bottom: 5px;
}
.react-draggable strong {
  background: #ddd;
  border: 1px solid #999;
  display: block;
  // margin-bottom: 10px;
  padding: 3px 5px;
  // text-align: center;
}

.project-container {
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.project-pic {
  width: 100%;
  height: 20vh;
  margin-bottom: 15px;
}


.react-draggable,
.cursor {
  cursor: move;
}
.no-cursor {
  cursor: auto;
}
.cursor-y {
  cursor: ns-resize;
}
.cursor-x {
  cursor: ew-resize;
}

.project-items{
  color: rgba(255, 255, 255, 0.29);
  border-radius: 2em !important;
  margin: 5%; 
  text-align: center;
  // height: 5em;

  
  
}

.project-container{
  max-width: 80%;
  text-align: center;
  a {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-left: 50px;
  }

  #Button{
    margin: 5px;
  }

}

.project-title{
  color: #999;
  text-align: center
}

.col-image{
  text-align: center;
  margin-bottom: 40px;
}

.project-image{
  width: 50%;
  
}

// ------------------- Content page ------------------- 
.contact-container {
  max-width: 100%;
}

.contact-detail {
  font-family: "Acme", sans-serif;
  background: rgba(255, 255, 255, 0.29);

  // padding: 8% 5%;
  padding-bottom: 15px;

  strong {
    font-size: 25px;
  }

  p {
    font-size: 20px;
    color: white;
  }

  h2 {
    font-family: "Acme", sans-serif;
  }

  li {
    display: inline;
  }

  li a {
    color: #4d4d4e;
    text-decoration: none;
    padding: 0 20px;

    font-size: 50px;
    line-height: 16px;
  }
  li a:hover,
  li a:focus {
    transition-duration: 0.5s;
    color: darkmagenta;
    text-decoration: underline;
  }
}
.contact-map {
  background-color: yellow;
  height: 93.1vh;
  padding-left: 0;
  padding-right: 0;
}

.something {
  background-color: yellowgreen;
}

// // ------------------- Media Queries ------------------- 

// // Smaller devices (landscape phones, 576px and up)
// @media (max-width: 576px) { 
//   .about {
//     background-color:green;
//   }
//   .about .title {
//     display:none;
//   }
// }

// // Small devices (landscape phones, 576px and up)
// @media (min-width: 576px) { 
//   .about {
//     background-color:orange;
//   }
//   .about .title {
//     display:none;
//   }

  
// }

// // Medium devices (tablets, 768px and up)
// @media (min-width: 768px) { 
//   .about {
//     background-color:blue;
//   }
//   .about .title {
//     display:block;
//   }
// }

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) { 
//   .about {
//     background-color:yellow;
//   }
//   .about .title {
//     display:block;
//   }
// }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) { 
//   .about {
//     background-color:red;
//   }
//   .about .title {
//     display:block;
//   }
// }
